import html2pdf from 'html2pdf.js';

export const downloadPdf = async (elementId, filename) => {
  const element = document.getElementById(elementId);
  const opt = {
    filename,
    pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
    image: { type: 'png', quality: 0.95 },
    html2canvas: { scale: 1, useCORS: true },
    jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
  };

  await html2pdf()
    .from(element)
    .set(opt)
    .toPdf()
    .get('pdf')
    .save();
};
